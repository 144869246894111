.lsv-nav {
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  &__link {
    margin-right: 10px;
    border-radius: 10px;
    padding: 13px 30px;
    font-size: $sm;
    font-weight: $bold;
    line-height: 1;
    color: var(--themeGray500);
    background-color: var(--white);

    &:last-child {
      margin-right: 0;
    }

    &:hover,
    &--is-active{
      color: var(--white);
      background-color: var(--themeCorporate500);
    }
  }
}