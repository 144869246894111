.products-performing {
  --chartWidth: 165px;
  --percentageWidth: 50px;
  --offset: 50px;

  &__top {
    display: flex;
  }

  &__column {
    &--chart {
      width: var(--chartWidth);
    }

    &--list {
      width: calc(100% - (var(--chartWidth) + var(--percentageWidth) - var(--offset)));
    }

    &--percentage {
      width: var(--percentageWidth);
    }
  }

  &__title {
    margin: 0 0 20px 0;
    font-size: 10px;
    text-transform: uppercase;
    color: var(--themeGray400);
  }

  &__content {
    display: flex;
  }

  &__donut {
    width: var(--chartWidth);
  }

  &__products-list {
    --listOffset: 20px;

    display: flex;
    flex-direction: column;
    width: calc(100% - var(--chartWidth) + var(--listOffset));
    margin: 7px 0 0 calc(var(--listOffset) * -1);

    &__item {
      display: flex;
      align-items: center;

      &__color {
        --dimensions: 14px;

        flex: 0 0 var(--dimensions);
        width: var(--dimensions);
        height: var(--dimensions);
        border-radius: 3px;
        margin-right: 5px;
      }

      &__name {
        padding-right: 5px;
        font-size: 10px;
        line-height: 13px;
        @include ellipsis();
      }

      &__amount {
        margin: 0 0 5px auto;
        font-size: $sm;
        font-weight: $bold;
        line-height: 20px;
      }
    }
  }

  &__skeleton {
    &-chart {
      min-width: 165px;
      padding-top: 8px;
      height: 148px;
    }

    &-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 25px;
      padding: 4px 0;
    }

    &-item {
      display: flex;

      &__circle {
        --dimensions: 131px;

        position: relative;
        top: -3px;
        left: -1px;
        width: var(--dimensions);
        height: var(--dimensions);
        border: 12px solid var(--themeGray100);
        background-color: var(--white);

        &::before {
          display: none;
        }
      }

      &__line {
        &--square {
          --dimensions: 16px;

          width: var(--dimensions);
          height: var(--dimensions);
        }

        &--long {
          width: calc(100% - 16px - 17%);
          height: 16px;
        }

        &--short {
          width: 15%;
          height: 16px;
        }
      }
    }
  }
}