.calendar {
  position: absolute;
  top: 10px;
  right: 17px;

  &__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--themeGray100);
    padding: 7px 8px;
    font-size: $xs;
    line-height: 16px;
    color: var(--themeBaseBlack);
    background: var(--white);

    &:hover {
      cursor: pointer;
    }

    &__icon {
      margin-right: 5px;
    }

    &__arrow {
      margin-left: 5px;
    }

    &--cancel,
    &--apply {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 90px;
      border-radius: 3px;
      padding: 5px;
      font-size: 10px !important;
      font-weight: $regular !important;
      line-height: 13px;
      transition: .3s all ease-in-out;

      &:hover {
        cursor: pointer;
      }
    }

    &--cancel {
      border-color: var(--themeCorporateLight);
      color: var(--themeGray700);
      background-color: var(--themeCorporateLight);

      &:hover {
        color: var(--themeGray700);
        background-color: var(--themeCorporateLight);
        opacity: .7;
      }
    }

    &--apply {
      border-color: var(--themeCorporate500);
      color: var(--white);
      background-color: var(--themeCorporate500);

      &:hover {
        color: var(--white);
        background-color: var(--themeCorporate500);
        opacity: .8;
      }
    }
  }
}

.daterangepicker {
  font-family: 'Montserrat', sans-serif;

  &.show-ranges {
    &.ltr {
      .drp-calendar {
        &.left {
          border: none;
        }
      }
    }
  }

  &.show-calendar {
    .drp-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .ranges {
      margin-top: 0;
    }
  }

  .ranges {
    border-right: 1px solid #ddd;

    li {
      transition: .2s all ease-in-out;

      &.active {
        background-color: var(--themeCorporate500);

        &:hover {
          background-color: var(--themeCorporate500);
        }
      }

      &:hover {
        background-color: var(--themeCorporateLight);
      }
    }
  }

  td, th {
    transition: .2s all ease-in-out;

    &.available {
      &:hover {
        background-color: #D9BEF6;
      }
    }

    &.in-range {
      background-color: var(--themeCorporateLight);
    }

    &.active {
      background-color: var(--themeCorporate500);

      &:hover {
        background-color: var(--themeCorporate500);
      }
    }
  }
}