.sidebar-toggle {
  position: absolute;
  top: 23px;
  right: 20px;

  &:hover {
    cursor: pointer;
  }

  &--toolbar {
    top: 0;
    right: 0;
  }
}