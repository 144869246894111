.lsv {
  $containerHeight: 470px;

  &-container {
    margin-bottom: 40px;
    border-radius: 10px;
    padding: 40px 50px 50px 50px;
    background-color: var(--white);
  }

  &-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid var(--themeGray100);
  }

  &-title {
    margin: 0 0 60px 0;
    font-size: $xl;
    color: var(--black);
  }

  &-steps {
    display: flex;
    flex-direction: column;

    &__text {
      margin-bottom: 10px;
      text-align: right;
      font-size: $xs;
      line-height: 130%;
      color: var(--themeCorporate600);
    }

    &__progress {
      position: relative;
      width: 240px;
      height: 10px;
      border-radius: 10px;
      background-color: var(--themeCorporate200);
      
      &__current {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 10px;
        background-color: var(--themeCorporate600);
        transition: .3s all ease-in-out;

        &--step-1 {
          width: 33%;
        }

        &--step-2 {
          width: 66%;
        }

        &--step-3 {
          width: 100%;
        }
      }
    }
  }

  &-form {
    min-height: $containerHeight;
    padding: 40px 120px 0 120px;

    &__block {
      margin-bottom: 30px;

      &__name {
        margin: 0 0 25px 0;
        font-size: $xs;
        line-height: 130%;
        color: var(--themeGray200);
      }

      &__row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
    }

    &__nav {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--align-right {
        justify-content: flex-end;
      }
    }
  }

  &-loader {
    position: relative;
    display: flex;
    min-height: $containerHeight;

    &__item {
      --dimensions: 300px;

      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      width: var(--dimensions);
      height: var(--dimensions);
      margin: auto;
      border-radius: 50%;
      background-color: var(--white);
      background-size: 100%;
      background-image: linear-gradient(-55deg, #f8f8fe 0%, rgba(0, 0, 0, 0) 60%), linear-gradient(5deg, #b99ff0 10%, rgba(0, 0, 0, 0) 60%), linear-gradient(80deg, #aa44e2 58%, rgba(0, 0, 0, 0) 70%);
      animation: rotate 1.5s infinite linear;

      &::before {
        content: '';
        position: absolute;
        top: -5px;
        right: 108px;
        display: block;
        width: 26px;
        height: 42px;
        border-radius: 20px;
        background-color: var(--themeCorporate600);
        transform: rotate(-76deg);
      }

      &::after {
        --dimensions: 250px;

        content: "";
        position: relative;
        display: block;
        width: var(--dimensions);
        height: var(--dimensions);
        margin: 25px auto;
        border-radius: 50%;
        background: var(--white);
      }
    }

    @-webkit-keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
  }
}