@mixin latestDonationItemContainer {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 5% 10px 0;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 95%;
    height: 1px;
    background-color: var(--themeGray100);
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;

    &::after {
      display: none;
    }
  }
}

.latest-donation-item {
  @include latestDonationItemContainer();

  &__img {
    --dimensions: 60px;

    width: var(--dimensions);
    height: var(--dimensions);
    margin-right: 10px;
    border: 1px solid var(--themeGray200);
    border-radius: 50%;
  }

  &__data {
    display: flex;
    flex-direction: column;
    width: calc(100% - 70px);
  }

  &__user-name {
    margin-bottom: 2px;
    font-size: $sm;
    font-weight: $bold;
    line-height: 20px;
    color: var(--themeBaseBlack);
  }

  &__amount {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    font-size: $xs;
    line-height: 16px;

    &__value {
      font-weight: $bold;
      white-space: nowrap;
      color: var(--themeCorporate500);
    }

    &__product {
      margin-left: 5px;
      @include ellipsis();
    }
  }

  &__date {
    margin: 0;
    font-size: 10px;
    color: var(--themeGray500);
  }

  &__skeleton-item {
    @include latestDonationItemContainer();

    &__right {
      width: calc(100% - 70px);
      margin-left: 10px;
    }

    &__circle {
      --dimensions: 60px;

      width: var(--dimensions);
      height: var(--dimensions);
    }

    &__line {
      height: 17px;

      &--top {
        width: 70%;
      }

      &--middle {
        width: 100%;
        margin: 2px 0;
      }

      &--bottom {
        width: 80%;
      }
    }
  }
}