$xs: 12px;
$sm: 14px;
$base: 16px;
$lg: 18px;
$xl: 20px;
$xl2: 24px;
$xl3: 30px;
$xl4: 36px;
$xl5: 48px;
$xl6: 60px;
$xl7: 72px;
$xl8: 96px;
$xl9: 128px;
$regular: 400;
$bold: 700;