.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 30px 10px 30px;
  }

  &__icon {
    margin-bottom: 50px;
  }

  &__title {
    max-width: 700px;
    margin: 0 0 50px 0;
    text-align: center;
    font-size: $xl5;
    line-height: 1;
  }

  &__image {
    display: block;
    width: 90%;
    margin-bottom: 50px;
  }
}