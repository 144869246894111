.module {
  position: relative;
  margin: 20px 0;
  border-radius: 10px;
  padding: 20px;
  //overflow: hidden;
  background: var(--white);
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));

  &__title {
    display: flex;
    align-items: flex-end;
    margin: 0 0 20px 0;
    border-bottom: 1px solid var(--themeGray100);
    padding: 0 0 20px 0;
    font-size: $sm;
    font-weight: $bold;
    line-height: 20px;
  }

  &__description {
    margin-left: 10px;
    font-size: 10px;
    line-height: 17px;
  }

  &__text {
    margin: 0;
    padding: 0 0 10px;
    font-size: 13px;
    color: var(--themeBaseBlack);
  }

  &__btn {
    position: relative;
    top: 3px;
    border: none;
    margin: 0 0 0 auto;
    opacity: 1;
    transition: .3s all ease-in-out;

    &:hover {
      opacity: .8;
      cursor: pointer;
    }
  }
}