.skeleton {
  position: relative;
  overflow: hidden;
  background: var(--themeGray100);

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to right, var(--themeGray100) 0%, rgba(0,0,0,0.07) 20%, var(--themeGray100) 40%, var(--themeGray100) 100%);
    background-repeat: no-repeat;
    background-size: 450px 400px;
    animation: shimmer 1.2s linear infinite;
  }

  &__line,
  &__vertical-line {
    border-radius: 5px;
  }

  &__circle {
    border-radius: 50%;
  }

  &__y-axis {
    width: 20px;
    border-radius: 7px 7px 0 7px;
  }

  &__x-axis {
    height: 20px;
    border-radius: 0 7px 7px 0;
  }
}

@keyframes shimmer {
  0%{
    background-position: -450px 0;
  }
  100%{
    background-position: 450px 0;
  }
}