.sidebar-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(var(--sidebarWidth) - var(--sidebarToolsWidth));
  height: 100%;
  padding: 20px 0;
  background-color: var(--sidebarBg);
  transition: .3s width ease-in-out;

  &--is-collapsed {
    width: 0;
  }

  &__top {
    opacity: 1;
    margin-top: 60px;
    padding: 0 20px;
    height: calc(100% - 160px);
    overflow-y: auto;

    &--is-collapsed {
      opacity: 0;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    opacity: 1;
    padding: 0 20px;

    &--is-collapsed {
      opacity: 0;
    }
  }

  &__title {
    margin: 0 0 20px 0;
    padding: 0 10px;
    font-size: 10px;
    font-weight: $bold;
    text-transform: uppercase;
    color: var(--themeGray200);
  }

  &__item {
    $item: &;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 38px;
    margin: 0 0 10px 0;
    border-radius: 4px;
    padding: 9px 10px;
    font-size: $sm;
    font-weight: $bold;
    line-height: 20px;
    white-space: nowrap;
    color: var(--sidebarTxtColor);
    transition: .3s all ease-in-out;

    &--has-child {
      margin-bottom: 0;

      &#{$item} {
        &--is-disabled {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    &--bottom {
      height: 40px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &--w-icon {
        justify-content: flex-start;
      }
    }

    &--is-active,
    &:hover {
      background-color: var(--sidebarCurrentLinkBg);
      cursor: pointer;
    }

    &--is-disabled {
      opacity: .5;
      
      &:hover {
        cursor: not-allowed;
      }
    }

    &--is-user {
      padding: 0;

      &#{$item} {
        &--is-active {
          background-color: transparent;

          #{$item} {
            &__user {
              background-color: var(--sidebarCurrentLinkBg)
            }
          }
        }

        &--is-disabled {
          opacity: 1;

          &:hover {
            background-color: transparent;
            cursor: inherit;
          }

          #{$item} {
            &__user {
              opacity: .5;
              
              &:hover {
                cursor: not-allowed;
              }
            }
          }
        }
      }
    }

    &__icon {
      transform: rotate(0);
      transition: .3s all ease-in-out;

      &--is-active {
        transform: rotate(180deg);
      }
    }

    &__user {
      display: flex;
      flex-direction: column;
      width: calc(100% - 40px);
      border-radius: 4px;
      padding: 2px 10px;
      transition: .3s all ease-in-out;

      &:hover {
        background-color: var(--sidebarCurrentLinkBg);
        cursor: pointer;
      }

      &__name {
        @include ellipsis();
      }

      &__company {
        font-size: $xs;
        line-height: 16px;
        @include ellipsis();
      }
    }

    &__external-link-icon {
      margin-left: 5px;
    }
  }

  &__item-with-child {
    margin-bottom: 15px;
  }

  &__sub-list {
    max-height: 0;
    overflow: hidden;
    padding: 0 0 0 15px;
    transition: .3s max-height ease-out;

    &--is-opened {
      transition: .8s max-height ease-in;
      max-height: 1000px;
    }
  }

  &__sub-item {
    display: block;
    border-bottom: 1px solid var(--themeGray500);
    padding: 8px 5px;
    font-size: $xs;
    font-weight: $regular;
    line-height: 15px;
    white-space: nowrap;
    color: var(--sidebarTxtColor);
    transition: .3s all ease-in-out;

    &:hover {
      padding-left: 8px;
    }

    &--is-active {
      font-weight: $bold;
    }
    
    &--is-disabled {
      opacity: .5;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &__logout {
    --dimensions: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--dimensions);
    height: var(--dimensions);
    border-radius: 8px;
    transition: .3s all ease-in-out;

    &:hover {
      background-color: var(--sidebarIconForeground);
      cursor: pointer;
    }
  }
}