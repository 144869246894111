.top-products {
  --chartHeight: 365px;
  --offset: 20px;

  &__skeleton-item {
    height: var(--chartHeight);
    padding-left: 17px;

    &__y-axis {
      height: calc(var(--chartHeight) - var(--offset));
    }

    &__verticals {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding-right: 25px;
    }

    &__horizontals {
      position: relative;
      top: calc(var(--offset) * -1);
      left: var(--offset);
      width: calc(100% - var(--offset));
    }

    &__vertical-line {
      width: 7%;
      opacity: .6;

      &--type-1 {
        height: calc(var(--chartHeight) - 100px);
      }

      &--type-2 {
        height: calc(var(--chartHeight) - 20px);
      }

      &--type-3 {
        height: calc(var(--chartHeight) - 50px);
      }
    }
  }
}