.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  border-radius: 3px;
  padding: 5px;
  font-size: 10px;
  font-weight: $regular;
  line-height: 13px;
  color: var(--white);

  &--primary {
    background-color: var(--themeBlue700);
  }

  &--warning {
    background-color: var(--themeYellow500);
  }

  &--failed {
    background-color: var(--themeRed500);
  }

  &--success {
    background-color: var(--themeGreen500);
  }
}