.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: var(--footerHeight);
  padding: 15px 30px;
  background: var(--white);

  &__credits {
    display: flex;
    align-items: center;
    margin: 0;

    &__link {
      margin-left: 5px;
      color: var(--black);

      &:hover {
        color: var(--themeCorporate500);
      }
    }
  }
}