.button-preloader {
  --preloaderWidth: 70px;
  --preloaderHeight: 18px;

  position: absolute;
  left: calc(50% - var(--preloaderWidth) /2);
  top: calc(50% - var(--preloaderHeight) / 2);
  width: var(--preloaderWidth);

  &__item {
    display: inline-block;
    width: var(--preloaderHeight);
    height: var(--preloaderHeight);
    border-radius: 100%;
    background-color: var(--white);
    animation: preloaderAnimation 1.4s infinite ease-in-out both;

    &--1 {
      animation-delay: -0.32s;
    }

    &--2 {
      animation-delay: -0.16s;
    }

    &--3 {

    }
  }
}

@-webkit-keyframes preloaderAnimation {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0)
  }

  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes preloaderAnimation {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1.0);
  }
}