.radio {
  // variables scope
  $color__background: var(--white) !default;
  $color__default: var(--themeGray500) !default;
  $color__type__1: var(--themeCorporate500) !default;
  $color__type__2: var(--themeCorporate600) !default;
  $color__type__3: $color__default !default;
  $color__error: var(--themeRed500) !default;
  $color__disabled: var(--themeGray200) !default;
  $font__size: 14px !default;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  &__container {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;

    &__label {
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
    }

    &--row {
      flex-direction: row;
      align-items: center;
    }

    &--column {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__input {
    display: none;

    &:checked {
      & + label {
        &::after {
          opacity: 1;
          transform: scale(1);
          transition: .3s all ease-in;
        }
      }
    }
  }

  &__label {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
    font-size: $font__size;
    font-weight: 400;
    line-height: 1;
    color: $color__default;
    transition: .3s all ease-in-out;

    &::before,
    &::after {
      content: '';
      width: var(--dimensions);
      height: var(--dimensions);
      border-radius: 50%;
    }

    &::before {
      --dimensions: 20px;

      position: relative;
      margin-right: 10px;
      border-width: 2px;
      border-style: solid;
      background-color: $color__background;
    }

    &::after {
      --dimensions: 10px;
      --offset: 5px;

      position: absolute;
      top: var(--offset);
      left: var(--offset);
      opacity: 0;
      transform: scale(0);
      transition: .3s all ease-out;
    }

    &:hover {
      cursor: pointer;
    }

    $types: (
            "type-1": #{$color__type__1},
            "type-2": #{$color__type__2},
            "type-3": #{$color__type__3},
            "is-disabled": #{$color__disabled},
    );

    @each $type, $color in $types {
      &--#{$type} {
        &::before {
          border-color: $color;
        }

        &::after {
          background-color: $color;
        }

        &:hover {
          color: $color;
        }
      }
    }

    &--is-disabled {
      color: $color__disabled;

      &:hover {
        cursor: not-allowed;
      }
    }

    &--is-error {
      &::before {
        border-color: $color__error;
      }
    }
  }
}