.sidebar {
  position: fixed;
  display: flex;
  width: var(--sidebarWidth);
  height: 100vh;
  box-shadow: var(--sidebarShadow);
  transition: .3s width ease-in-out;
  z-index: 1;

  &--is-collapsed {
    width: var(--sidebarToolsWidth);
  }
}