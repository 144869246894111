.page-title {
  padding: 10px 0;

  &__text {
    margin: 0 0 10px 0;
    font-size: $xl2;
    font-weight: 500;
    line-height: 1;
  }

  &__description {
    margin: 0 0 10px 0;
    font-size: $sm;
    line-height: 1;
  }
}