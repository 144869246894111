.input {
  // variables scope
  $color: var(--theme-black) !default;
  $color__background: var(--white) !default;
  $color__inactive: var(--themeGray500) !default;
  $color__active: var(--themeCorporate500) !default;
  $color__error: var(--themeRed500) !default;
  $min-width: 200px !default;
  $font__size: 16px !default;
  $font__size__msg: 12px !default;

  --actionOffset: 40px;

  @mixin font {
    font-size: $font__size;
    font-weight: 400;
    line-height: 1;
  }

  position: relative;
  min-width: $min-width;
  width: 100%;

  &__container {
    display: flex;
    justify-content: flex-start;

    &--row {
      flex-direction: row;
      align-items: flex-start;

      .input {
        margin: 0 20px 20px 0;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &--column {
      flex-direction: column;
      align-items: flex-start;

      .input {
        margin: 0 0 20px 0;
      }
    }
  }

  &__field {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    border: 1px solid $color__inactive;
    border-radius: 8px;
    padding: 0 12px;
    @include font;
    color: $color;
    background-color: $color__background;

    &:focus {
      outline: none;
      border-color: $color__active;
      background-color: $color__background;
      box-shadow: none;

      &:placeholder-shown {
        & + label {
          color: $color__active;
        }
      }
    }

    &:not(:placeholder-shown) {
      &:focus {
        & + label {
          color: $color__active;
        }
      }
    }

    &:focus,
    &:not(:placeholder-shown) {
      & + label {
        left: 10px;
        top: -6px;
        padding-left: 3px;
        padding-right: 3px;
        transform: scale(.75);
      }
    }

    &:disabled {
      border-style: dotted;
      color: $color__inactive;

      &:hover {
        cursor: not-allowed;
      }

      & + label {
        &:hover {
          cursor: not-allowed;
        }
      }
    }

    &--has-action {
      padding-left: var(--actionOffset);
    }

    &--type-medium {
      height: 52px;

      & + label {
        top: 18px;
      }
    }

    &--type-small {
      height: 38px;

      & + label {
        top: 11px;
      }
    }

    &--is-error {
      border-color: $color__error;

      &:focus,
      &:not(:placeholder-shown) {
        & + label {
          color: $color__error;
        }
      }
    }
  }

  &__label {
    position: absolute;
    left: 13px;
    top: 18px;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    @include font;
    color: $color__inactive;
    background-color: $color__background;
    transform: scale(1);
    transition: .3s all ease-in-out;

    &--has-action {
      left: var(--actionOffset);
    }
  }

  &__msg {
    display: block;
    max-height: 50px;
    overflow: hidden;
    padding: 0 0 0 13px;
    font-size: $font__size__msg;
    font-weight: 400;
    line-height: 1.7;
    color: $color__inactive;
    transition: .3s max-height ease-in;

    &--is-hidden {
      transition: .3s max-height ease-in;
      max-height: 0;
    }

    &--is-error {
      color: $color__error;
    }
  }

  &__visibility-action {
    --dimensions: 24px;

    position: absolute;
    left: 12px;
    width: var(--dimensions);
    height: var(--dimensions);

    &:hover {
      cursor: pointer;
    }

    &--type-medium {
      top: 14px;
    }

    &--type-small {
      top: 7px;
    }

    &__on,
    &__off {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      visibility: hidden;
      transition: .2s all ease-in-out;

      &--is-enabled {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}