.total-amount {
  &__title {
    margin: 0 0 20px 0;
    font-size: 10px;
    font-weight: $bold;
    text-transform: uppercase;
    color: var(--themeGray400);
  }

  &-item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &__progress {
      margin-bottom: 5px;

      &__line {
        position: relative;
        height: 10px;
        width: 100%;
        border-radius: 29px;
        background-color: var(--themeGray100);
      }

      &__fill {
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        border-radius: 29px;
        background-color: var(--themeCorporate500);
        animation: 1s progressFilling linear;
      }
    }

    &__data {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      margin: 0;
      font-size: $xs;
      font-weight: $regular;
      line-height: 16px;
    }

    &__amount {
      font-size: $sm;
      font-weight: $bold;
      line-height: 20px;
    }

    &__skeleton-item {
      height: 35px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &__bar {
        height: 10px;
        margin-bottom: 5px;
      }

      &__sides {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__left {
        width: 55%;
        height: 16px;
      }

      &__right {
        width: 30%;
        height: 20px;
      }
    }
  }
}

@keyframes progressFilling {
  0% {
    width: 0;
  }
}