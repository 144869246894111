.toast {
  --toast-success-color: var(--themeGreen500);
  --toast-info-color: var(--themeBlue700);
  --toast-warning-color: var(--themeYellow500);
  --toast-danger-color: var(--themeRed500);
  --toast-width: 425px;

  position: fixed;
  top: 15px;
  right: calc(var(--toast-width) * -1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: var(--toast-width);
  height: 100px;
  border: 1px solid;
  border-radius: 16px;
  padding: 10px 12px;
  z-index: 1;
  transition: .2s right ease-in;

  &--is-visible {
    transition: .2s right ease-in;
    right: 15px;
  }

  &--success {
    border-color: var(--themeGreen600);
    background-color: var(--toast-success-color);
  }

  &--info {
    border-color: var(--themeBlue800);
    background-color: var(--toast-info-color);
  }

  &--warning {
    border-color: var(--themeYellow600);
    background-color: var(--toast-warning-color);
  }

  &--danger {
    border-color: var(--themeRed600);
    background-color: var(--toast-danger-color);
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__icon {
    --dimensions: 70px;

    display: flex;
    flex: 0 0 var(--dimensions);
    align-items: center;
    justify-content: center;
    width: var(--dimensions);
    height: var(--dimensions);
    margin-right: 16px;
  }

  &__text {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 140%;
    color: var(--white);
  }

  &__close {
    --dimensions: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--dimensions);
    height: var(--dimensions);

    &:hover {
      cursor: pointer;
    }
  }
}