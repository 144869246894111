.sidebar-tools {
  position: relative;
  display: flex;
  flex-direction: column;
  width: var(--sidebarToolsWidth);
  height: 100%;
  padding: 20px;
  background-color: var(--sidebarToolsBg);
  z-index: 1;

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 30px);
    padding-top: 60px;
  }

  &__item {
    $item: &;
    --dimensions: 40px;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--dimensions);
    height: var(--dimensions);
    margin-bottom: 20px;
    border-radius: 8px;
    transition: .3s all ease-in-out;

    &:hover {
      background-color: var(--sidebarIconForeground);
      cursor: pointer;

      #{$item} {
        &__tooltip {
          left: 43px;
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &--is-active {
      background-color: var(--sidebarIconForeground);
    }

    &--is-disabled {
      opacity: .5;

      &:hover {
        cursor: not-allowed;
      }
    }

    &__tooltip {
      position: absolute;
      left: 20px;
      border-radius: 5px;
      padding: 9px 20px;
      opacity: 0;
      visibility: hidden;
      font-size: 10px;
      font-weight: $bold;
      text-transform: uppercase;
      color: var(--sidebarTooltipText);
      background: var(--sidebarIconForeground);
      transition: all .4s cubic-bezier(.68,-.55,.265,1.55) 0s;
    }
  }

  &__profile {
    --dimensions: 40px;

    position: relative;
    display: flex;
    width: var(--dimensions);
    height: var(--dimensions);
    border-radius: 50%;
    background: var(--white);

    .sidebar-tools {
      &__item {
        &__tooltip {
          top: 5px;
        }
      }
    }

    &:hover {
      cursor: pointer;

      .sidebar-tools{
        &__item {
          &__tooltip {
            left: 43px;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    &--is-disabled {
      opacity: .5;

      &:hover {
        cursor: not-allowed;
      }
    }

    &__img {
      display: block;
      width: 100%;
      height: 100%;
      border: 2px solid var(--sidebarProfileBorder);
      border-radius: 50%;
      padding: 2px;
    }
  }
}