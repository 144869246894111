.login {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: #f2f6f9;

  &__container {
    position: relative;
    top: -50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 630px;
  }

  &__logo {
    margin-bottom: 60px;
  }

  &__area {
    width: 100%;
    border-radius: 10px;
    padding: 90px 65px 95px 65px;
    background-color: var(--white);
    box-shadow: 0 4px 130px rgba(0, 0, 0, 0.1);

    &--form,
    &--forgot {
      position: relative;
      left: 0;
      opacity: 0;
      animation-delay: 0.25s;
      animation: 0.5s ease 0s 1 normal forwards running fadeInRight;
    }
  }

  &__title {
    margin: 0 0 30px 0;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    line-height: 130%;
    color: var(--themeBaseBlack);
  }

  &__description {
    margin: 0 0 20px 0;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 130%;
    color: var(--themeBaseBlack);
  }

  &__form {
    $form: &;
    $field-font-size: 14px;

    &__field {
      width: 100%;
      height: 52px;
      margin-bottom: 15px;
      border: 2px solid var(--themeCorporate500);
      border-radius: 5px;
      padding: 16px 20px;
      font-size: 14px;
      font-weight: 700;
      line-height: 140%;
      color: var(--themeCorporate500);

      &:focus,
      &:not(:placeholder-shown) {
        outline: none;

        & + label {
          left: 8px;
          top: -8px;
          font-size: 10px;
          font-weight: 400;
          background-color: var(--white);
        }
      }

      &--is-error {
        border-color: var(--themeRed500);
        color: var(--themeRed500);

        & + label {
          color: var(--themeRed500);
        }
      }

      &-group {
        position: relative;
      }
    }

    &__label {
      position: absolute;
      left: 15px;
      top: 14px;
      padding: 2px 4px;
      font-size: $field-font-size;
      font-weight: 700;
      line-height: 140%;
      color: #9ca8ba;
      background-color: transparent;
      transition: .3s all ease-in-out;
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &__forgot {
      position: relative;
      font-size: 12px;
      font-weight: 700;
      line-height: 130%;
      color: var(--themeGray100);
      transition: .3s all ease-in-out;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -3px;
        width: 100%;
        height: 1px;
        background-color: var(--themeCorporate500);
        transition: .3s all ease-in-out;
      }

      &:hover {
        color: var(--themeCorporate500);
        cursor: pointer;

        &::after {
          background-color: var(--themeCorporate500);
        }
      }
    }

    &__submit {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 48px;
      border: 2px solid var(--themeCorporate500);
      border-radius: 100px;
      font-size: 16px;
      font-weight: 700;
      line-height: 150%;
      color: var(--white);
      background-color: var(--themeCorporate500);
      transition: .3s all ease-in-out;

      &:hover {
        color: var(--themeCorporate500);
        background-color: var(--white);
        cursor: pointer;
      }

      &--is-loading {
        opacity: .6;
        cursor: not-allowed;
        
        &:hover {
          color: var(--white);
          background-color: var(--themeCorporate500);
          cursor: not-allowed;
        }
      }
    }

    &__error {
      position: absolute;
      width: 100%;
      margin: 20px 0 0 0;
      text-align: center;
      font-weight: bold;
      color: var(--themeRed500);
    }
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 50px;
  }

  100% {
    opacity: 1;
    left: 0;
  }
}