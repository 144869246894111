.stacked-bar {
  --stackedBarHeight: 515px;

  position: relative;

  .apexcharts-legend-marker {
    top: 2px !important;
  }

  &__skeleton-item {
    height: var(--stackedBarHeight);
    padding-left: 17px;

    &__y-axis {
      height: calc(var(--stackedBarHeight) - var(--offset));
    }

    &__verticals {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 81%;
    }

    &__horizontals {
      position: relative;
      top: calc(var(--offset) * -1);
      left: var(--offset);
      width: 82%;
    }

    &__vertical-line {
      width: 6%;
      opacity: .6;

      &--type-1 {
        height: calc(var(--stackedBarHeight) - 150px);
      }

      &--type-2 {
        height: calc(var(--stackedBarHeight) - 80px);
      }

      &--type-3 {
        height: calc(var(--stackedBarHeight) - 200px);
      }
    }

    &__legend {
      position: absolute;
      top: 26px;
      right: 10px;
      width: 160px;

      &__line {
        width: 100%;
        height: 15px;
        margin-bottom: 4px;
      }
    }
  }
}
