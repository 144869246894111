$col-count: 12 !default;
$col-spacing: 30px !default;

.container {
  position: relative;
  width: 100%;
  padding: 10px 15px;
  overflow-x: hidden;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -15px;

  &--reverse {
    flex-direction: row-reverse;
  }

  &--space-between {
    justify-content: space-between;
  }

  &--align-center {
    align-items: center;
    height: 100%;
  }
}

.col {
  flex-grow: 1;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 calc(#{$col-spacing} / 2);

  &--vertical {
    display: flex;
    flex-direction: column;

    &--center {
      justify-content: center;
    }
  }

  &--reverse {
    flex-direction: column-reverse;
  }

  @for $i from 1 through $col-count {
    &-xs-#{$i} {
      flex-basis: calc((#{$i} / #{$col-count}) * 100%);
      max-width: calc((#{$i} / #{$col-count}) * 100%);
    }
  }

  @for $i from 1 through $col-count {
    &-xs-offset-#{$i} {
      margin-left: calc((#{$i} / #{$col-count}) * 100%);
    }
  }
}