:root {
  // colors
  --white: #fff;
  --black: #000;
  --themeBaseBlack: #444441;
  --themeCorporateLight: #EDDCFF;
  --themeCorporate200: #A576D7;
  --themeCorporate500: #7E3BC6;
  --themeCorporate600: #63059B;
  --themeGreen500: #24CE54;
  --themeGreen600: #1CBF4A;
  --themeRed500: #EF353F;
  --themeRed600: #C52D35;
  --themeBlue700: #1E98B6;
  --themeBlue800: #0583A3;
  --themeYellow500: #F1C400;
  --themeYellow600: #C7A204;
  --themeGray50: #EEF2F8;
  --themeGray100: #DADEE5;
  --themeGray200: #B4BBC7;
  --themeGray400: #717E92;
  --themeGray500: #536074;
  --themeGray600: #485364;
  --themeGray700: #384253;
  --themeGray800: #1A2231;

  // dimensions
  --sidebarWidth: 340px;
  --sidebarToolsWidth: 80px;
  --footerHeight: 50px;
}

:root[data-theme="light"] {
  // colors
  --mainBg: var(--white);
  --sidebarBg: var(--white);
  --sidebarToolsBg: var(--themeGray50);
  --sidebarTxtColor: var(--themeBaseBlack);
  --sidebarCurrentLinkBg: var(--themeGray100);
  --sidebarIconForeground: var(--themeGray100);
  --sidebarTooltipText: var(--themeGray800);
  --sidebarProfileBorder: var(--themeBaseBlack);
  --sidebarShadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
}

:root[data-theme="dark"] {
  // colors
  --mainBg: var(--themeGray50);
  --sidebarBg: var(--themeGray700);
  --sidebarToolsBg: var(--themeGray600);
  --sidebarTxtColor: var(--white);
  --sidebarCurrentLinkBg: var(--themeGray500);
  --sidebarIconForeground: var(--themeGray400);
  --sidebarTooltipText: var(--white);
  --sidebarProfileBorder: var(--white);
  --sidebarShadow: none;
}

html,
body {
  height: 100%;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  color: var(--themeBaseBlack);
}

#root {
  min-height: 100%;
}

.app-container {
  position: relative;
  display: flex;
  height: 100%;
  min-height: 100vh;
}