.logo {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &__img {
    display: block;
    width: 40px;
    height: 30px;
  }

  &__link {
    opacity: 1;
    transform: scale(1);
    transition: .3s all ease-in;

    &--is-hidden {
      opacity: 0;
      transform: scale(0);
      transition: .3s all ease-out;
    }
  }

  &__collapse-icon {
    --dimensions: 24px;

    position: absolute;
    left: calc(50% - (var(--dimensions) / 2));
    top: calc(50% - (var(--dimensions) / 2));;
    width: var(--dimensions);
    height: var(--dimensions);
    opacity: 0;
    transform: scale(0);
    transition: .3s all ease-out;

    &--is-visible {
      opacity: 1;
      transform: scale(1);
      transition: .3s all ease-in;
    }
  }
}