.btn {
  border: 2px solid var(--themeCorporate500);
  border-radius: 100px;
  font-size: $base;
  font-weight: $bold;
  transition: .3s all ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &--regular {
    padding: 12px 40px;
  }

  &--type-1 {
    color: var(--white);
    background-color: var(--themeCorporate500);

    &:hover {
      color: var(--themeCorporate500);
      background-color: var(--white);
    }
  }

  &--type-2 {
    color: var(--themeCorporate500);
    background-color: var(--white);

    &:hover {
      color: var(--white);
      background-color: var(--themeCorporate500);
    }
  }
}