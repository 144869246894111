.transaction-chart {
  --chartHeight: 355px;
  --offset: 20px;

  height: var(--chartHeight);

  &__skeleton-item {
    position: relative;
    top: 10px;
    height: var(--chartHeight);
    padding-left: 17px;

    &__verticals {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }

    &__horizontals {
      position: relative;
      top: calc(var(--offset) * -1);
      left: var(--offset);
      width: calc(100% - var(--offset));
    }

    &__y-axis {
      height: calc(var(--chartHeight) - var(--offset));
    }

    &__x-axis {
      width: 100%;
    }

    &__vertical-line {
      width: 20px;
      opacity: .6;

      &--type-1 {
        height: calc(var(--chartHeight) - 100px);
      }

      &--type-2 {
        height: calc(var(--chartHeight) - 50px);
      }

      &--type-3 {
        height: calc(var(--chartHeight) - 150px);
      }
    }
  }

  .apexcharts-toolbar {
    .apexcharts-zoomin-icon,
    .apexcharts-zoomout-icon,
    .apexcharts-zoom-icon,
    .apexcharts-pan-icon,
    .apexcharts-reset-icon {
      transform: scale(.7);

      &.apexcharts-selected,
      &.apexcharts-selected:hover,
      &:hover {
        svg {
          fill: transparent;
          stroke: var(--themeCorporate500);
        }
      }

      svg {
        fill: transparent;
        stroke: var(--themeBaseBlack);
        transition: .3s all ease-in-out;
      }
    }

    .apexcharts-zoom-icon:not(.apexcharts-selected):hover {
      svg {
        fill: transparent;
        stroke: var(--themeCorporate500);
      }
    }
  }
}