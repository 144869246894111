.top-stats {
  $top-stats: &;

  &__item {
    position: relative;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 20px;
    background: var(--white);
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));

    &__title {
      font-size: 10px;
      font-weight: $bold;
      line-height: 13px;
      text-transform: uppercase;
      color: var(--themeGray400);
    }

    &__statistics {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__left,
      &__right {
        width: 50%;
      }

      &__right {
        display: flex;
        justify-content: flex-end;
        padding-left: 5px;
      }
    }

    &__dynamic-graph {
      width: 192px;
    }

    &__amount {
      margin: 0;
      font-size: $xl2;
      font-weight: $bold;
      line-height: 34px;
      text-transform: uppercase;
      @include ellipsis();
      color: var(--themeCorporate500);
    }

    &__percentage {
      display: flex;
      align-items: center;
      height: 12px;
    }

    &__dynamic {
      display: flex;
      align-items: center;

      &__arrow {
        margin-right: 4px;
      }

      &__value {
        margin-right: 5px;
        font-size: $xs;
        font-weight: $bold;

        &--is-positive {
          color: var(--themeGreen500);
        }

        &--is-negative {
          color: var(--themeRed500);
        }
      }
    }

    &__text {
      @include ellipsis();
    }
  }

  &__skeleton-item {
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 16px 20px 10px 20px;
    background: var(--white);
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));

    &--contacts {
      padding: 16px 20px;
    }

    &__top {
      width: 45%;
      height: 18px;
    }

    &__bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 3px 0 4px 0;

      &--contacts {
        margin-top: 5px;
      }

      &__left {
        width: 50%;

        &__top {
          width: 80%;
          height: 23px;
          margin-bottom: 5px;
        }

        &__bottom {
          height: 14px;
        }
      }

      &__right {
        width: 50%;
        padding-left: 10px;

        &__line {
          position: relative;
          top: -5px;
          width: 100%;
          height: 50px;
        }
      }
    }
  }
}