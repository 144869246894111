.form-field {
  &__field-group {
    position: relative;
    width: 48%;
    margin-bottom: 25px;
  }

  &__input {
    display: block;
    width: 100%;
    height: 56px;
    border: 1px solid var(--themeGray400);
    border-radius: 8px;
    padding: 15px 12px;
    font-size: $base;
    color: var(--themeGray400);
    transition: .3s all ease-in-out;

    &:focus,
    &:not(:placeholder-shown){
      outline: none;
      border-color: var(--themeCorporate600);

      & + label {
        left: 8px;
        top: -6px;
        padding: 2px 5px;
        font-size: $xs;
        background-color: var(--white);
      }
    }

    &--is-error {
      border-color: var(--themeRed500);
    }
  }

  &__label {
    position: absolute;
    left: 12px;
    top: 20px;
    font-size: $base;
    color: var(--themeGray400);
    transform: scale(1);
    transition: .3s all ease-in-out;

    &:hover {
      cursor: text;
    }
  }

  &__error-msg {
    margin: 5px 0 0 14px;
    font-size: $xs;
    color: var(--themeRed500);
  }
}